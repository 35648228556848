// não aplicar estilos quando logado
body:not(.userrole-authenticated) {
   label {
        font-size: 18px;
        color: $bg-quinario;
        font-weight: 600;
        &.form-check-label{
            font-weight: normal;
        }
    }
}

textarea.form-control{
    height: 10rem;
}
