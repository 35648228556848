#portal-footer-wrapper {
    background-color: #2e3133;
    padding: 0 0 15px 0;
    .portletWrapper:first-child{
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .markspeciallinks__icon{
        display: none;
    }
}

#tjdft-mapa-site {
    margin-bottom: 15px;
    h1, a.mapa, i{
        color: $bg-dominante;
        background-color: $bg-secundario;
        text-align: center;
    }
    h1 {
        margin: 0;
        position: relative;
    }
    a.mapa, a.mapa:link, a.mapa:hover, a.mapa:active {
        display: block;
        border: 0;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 0;
        text-decoration: none;
    }
    a.mapa:hover {
        text-decoration: underline;
    }
    a.mapa-site:hover {
        text-decoration: none;
    }
    i {
        margin: 0 auto;
        margin-top: -26px;
        padding-top: 26px;
        display: block;
        width: 75px;
        height: 55px;
        border-radius: 50% 50%;
        font-size: 0;
        cursor: pointer;
        &.glyphicon {
            position: inherit;
            &-menu-up {
                margin-top: -35px;
            }
        }
    }
     .bi-chevron-down,
     .bi-chevron-up {
        fill: $bg-dominante;
        width: 23px;
        height: 28px;
        path{
            stroke-width: 2;
        }
    }
    #mapa-filtro {
        display: none;
        input {
            border-radius: 0;
            width: 100%;
            display: block;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            color: #2B2B2B;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            &:focus{
                border-color: #64bee8;
                outline: 0;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(100,190,232,.6);
            }
            &::-ms-clear {
                display: none;
            }
        }
        .mapa-limpa {
            float: right;
            margin-top: -35px;

        }
        span {
            font-size: 14px;
            cursor: pointer;
            padding: 10px;
            display: none;
        }
    }
    &.filtro {
        #tjdft-portal-sitemap {
            columns: auto;
        }
        #mapa-filtro span {
            display: block;;
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        li>a::after,>li .tjdft-navTreeLevel1::after {
            display: none;
        }
        i.glyphicon-menu-up {
            margin-top: -26px
        }
    }
}

#tjdft-portal-sitemap {
    display: none;
    position: relative;
    background-color: $bg-secundario;
    columns: auto;
    padding: 15px 0;
    column-gap: 0;
    text-align: left;
    ul, li, ul.tjdft-navTreeLevel1 {
        display: block;
        width: auto;
        columns: auto;
        border: 0;
        background-color: transparent;
    }
    ul.tjdft-navTreeLevel1 {
        position: inherit;
    }
    li {
        a {
            border: 0;
            background-color: transparent;
            margin-left: 15px;
        }
        .tjdft-navTreeLevel1 {
            padding: 0;
        }
    }
    > li {
        > a {
            color: $bg-dominante;
            display: block;
            padding: 15px;
            &:hover {
                color: #fff;
                background-color: $bg-quaternario;
            }
        }
    }
    @include media-breakpoint-up(md) {
        columns: 3;
    }
     @include media-breakpoint-up(lg) {
        columns: 4;
    }
}

#portal-footer {
    address {
        color:#ccc;
        text-align: center;
        margin-bottom: 0;
    }
    p {
        text-align: center;
        margin-bottom: 0;
        a, a:hover, a:link, a:visited {
            text-decoration: underline;
            color: $bg-secundario;
        }
    }
    .redes {
        text-align: center;
        a {
            margin: 15px 8px 0 8px;
            display: inline-block;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            width: 30px;
            height: 30px;
            &:hover {
                opacity: 0.9;
            }
        }
        @include media-breakpoint-up(lg) {
            a {
                width: 40px;
                height: 40px;
            }
        }
    }
    .acessos {
        margin-top: 15px;
        text-align: center;
        .icone {
            display: inline-block;
            width: 30px;
            height: 30px;
        }
        .informacao {
            background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#informacao") no-repeat center center;
            background-size: cover;
        }
        .servicos {
            background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#servicos") no-repeat center center;
            background-size: cover;
        }
        .pesquisa {
            background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#pesquisa-de-satisfacao") no-repeat center center;
            background-size: cover;
        }
        .lgpd {
            background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#lgpd") no-repeat center center;
            background-size: cover;
        }
        a {
            color: $bg-secundario;
            display: inline-block;
            width: 75px;
            margin-bottom: 10px;
            text-decoration: none;
            span {
                display: block;
            }
            &:hover {
                text-decoration: none;
                opacity: 0.9;
            }
        }
        @include media-breakpoint-up(md) {
            margin-top: 0;
            .ultimo{
                margin-top:20px;
            }
            .icone {
                margin-right: 10px;
                width: 60px;
                height: 60px;
            }
            a {
                display: inline;
                text-align: left;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    width: 75px;
                }
            }
        }
    }
    .endereco{
        margin-top: 14px;
        padding-top: 14px;
    }
}

#tjdf-calendar {
    //copia do less do barceloneta do plone 5
    .portletHeader {
		background: #f2f1f1;
		font-weight: 500;
		font-size: 18px;
		padding: 13px 15px;
		color: #696969;
		> a {
		color: #006064;
        text-decoration: none;
		}
	}
    .portlet {
        margin-bottom: 0;
        &.portletCalendar .portletHeader {
            text-align: center;
            position: relative;
            .calendarPrevious, .calendarNext {
                width: 30px;
                height: 30px;
                padding: 0 1px 2px;
                border-radius: 50%;
                position: absolute;
                display: inline-block;
                left: 5px;
                font-size: 24px;
                margin-top: -3px;
                line-height: 28px;
                font-weight: 500;
            }
            .calendarPrevious:hover, .calendarNext:hover {
                background: #fff;
                text-decoration: none;}
            .calendarNext {
                right: 5px;
                left: inherit;}
        }
        .portletContent {
            font-size: 14px;
            border: 0;
            background: transparent;
            font-weight: 400;
            & > *, & > div:not(.portalMessage) { //all but ul and tables and portalMessage
                padding: 10px 15px;
                margin: 0;
                color: #696969;
            }
            > ul {
                padding: 0;
                list-style: none;
                position: relative;
                > li {
                    a {
                    display: block;
                    padding: 10px 15px 26px 15px;
                    position: relative;
                    z-index: 1;
                    color: #086ca3;
                    }
                    > a {border-top: 1px solid #e5e5e5;}
                    &:first-child > a {border-top: 0;}
                }
                ul {
                    padding-left:15px;
                    list-style: none;
                }
                .portletItemDetails {
                    display: block;
                    z-index: 0;
                    padding: 0 15px 10px 15px;
                    font-weight: 400;
                    position: relative;
                    margin-top: -26px;
                    font-size: 12px;
                    color: #696969;
                }
                li:hover {background: #fcfcfd;}
            }
        }
        &.portletCalendar table {
            width: 100%;
            text-align: center;
            vertical-align: middle;
            border-collapse: collapse;
            border-spacing: 0;
            tr {
                line-height: 30px;
            }
            thead {
                background: transparent;
                th {
                    color: #696969;
                    font-weight: 300;
                    height: 34px;
                    text-align: center;
                    vertical-align: middle;
                }
            }
            td {
                color: #666;
                background: #eeeeee;
                &:hover {
                    background: transparent;
                }
                &.today:hover,
                &.cal_has_events:hover {
                    background: transparent;
                }
                &.cal_prev_month {
                    color: #999;
                    background: transparent;
                }
            }
            td > * {
                border-radius: 50%;
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
            .cal_has_events {
                position: relative;
                a:hover {
                    background: #dbecfe;
                    text-decoration: none;
                    box-shadow: 0 0 0 5px #dbecfe;
                }
                a::after {
                    content: "•";
                    position: absolute;
                    margin-left: 50%;
                    left: -3px;
                    font-size: 20px;
                    color: #a7daf2;
                    margin-top: 9px;
                }
                a:hover::after {
                    color: #64bee8;
                }
            }
            .today > * {
                background: #e5e5e5;
                font-weight: 500;
                //using shadow to expand background is because then table is only 20px per cell and not 30px
                box-shadow: 0 0 0 5px #e5e5e5; color: #000;
            }
            tbody tr:first-child td > * {
                margin-top: 6px;
            }
            tbody tr:last-child td > * {
                margin-bottom: 6px;
            }
            .cal_next_month, .cal_prev_month {
                color: #000;
                &.cal_has_events > a {
                    color: lighten(#64bee8, 15%);
                }
                &.cal_has_events > a::after {
                    color: lighten(#64bee8, 25%);
                }
                &.cal_has_events > a:hover {
                    color: darken(#007bb1,15%);
                }
                &.cal_has_events > a:hover::after {
                    color: #64bee8;
                }
            }
        }
    }
    //fim da copia

    & {
        text-align: center;
    }
    a:hover {
        text-decoration: none;
    }
    .calendario {
        &, &:link, &:visited, &:hover, &:active {
            color: $bg-secundario;
            text-decoration: none;
        }
        span {
            margin-top: 5px;
            display: block;            
        }
        img {
            width: 40px;
            height: 40px;
        }
    }

    .portlet.portletCalendar {
        section, header {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        .calendario {
            display: none;
        }
        .portlet.portletCalendar {
            section, header {
                display: block;
            }
        }
        a, a:visited, a:link {
            color: $bg-secundario;
        }
        .portlet.portletCalendar table{
            .today.cal_has_events a {
                color: $bg-dominante;
            }
            .cal_has_events a{
                text-decoration: none;
                &:hover {
                    color: $bg-dominante;
                    background-color: $bg-senario;
                    box-shadow: 0 0 0 5px $bg-senario;
                    &::after{
                        color: $bg-terciario;
                    }
                }
                &::after {
                    color: $bg-terciario;
                }
            }
            .cal_prev_month.cal_has_events > a,
            .cal_next_month.cal_has_events > a{
                &:hover{
                    &::after{
                        color: $bg-terciario;    
                    }    
                }
                &::after{
                    color: $bg-terciario;
                }
            }
            thead th, td, td.cal_prev_month, td.cal_next_month {
                color: $bg-secundario;
                background-color: transparent;
                border-color: transparent;
            }
            td.cal_prev_month, td.cal_next_month, td.cal_prev_month a, td.cal_next_month a, td.cal_prev_month.cal_has_events a, td.cal_next_month.cal_has_events a {
                color: darken($bg-secundario, 32%);
            }
        }
        .portletHeader{
            color: $bg-secundario;
            background-color: transparent;
            border-color: transparent;
        }
    }
}

#site-actions-footer {
    margin-top: 15px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        li {
            line-height: 24px;
        }
    }
    a {
        color: $bg-secundario;
        text-decoration: none;
    }
    @include media-breakpoint-up(md) {
        display: none;
    }
}
