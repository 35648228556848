// agendas
.mode_ical {
    color: $bg-quaternario;
}
.vevent {
    .cal_date{
        min-width: 100px;
    }
    .cal_month {
        color: #fff;
        background: $bg-terciario;
        padding: 6px 9px;
        font-size: 18px;
        font-weight: bold;
    }
    .cal_day{
        color:#2B2B2B;
    }
    .tileHeadline{
        margin-top: 0;
        a {
            color: $bg-quaternario;
        }
    }
}
// calendário

.portlet-calendar{
    color:#696969;
    .card-header{
        background-color: $bg-secundario;
        font-size: 18px;
        color:#696969;
        padding: 13px 15px;
        .calendarPrevious,
        .calendarNext{
            width: 30px;
            height: 30px;
            padding: 0 1px 2px;
            border-radius: 50%;
            position: absolute;
            display: inline-block;
            left: 5px;
            font-size: 24px;
            margin-top: -3px;
            line-height: 28px;
            font-weight: 500;
            &:hover{
                background: #fff;
                text-decoration: none;
            }
        }
        .calendarNext{
            right: 5px;
            left: inherit;
        }
    }
    .card-body{
        background-color: $bg-secundario;
        background-color: #f2f1f1;
        padding: 0;
        table{
            text-align: center;
        }
        tr{
            line-height: 30px;
        }
        th{
            border: 1px solid #e5e5e5;
            border-top: 0;
            border-bottom: 0;
            padding: 0;
            color: #696969;
            font-weight: 300;
            height: 34px;
            text-align: center;
            vertical-align: middle;
        }
        td{
            color: #666;
            background-color: $bg-secundario;
            background-color: #f2f1f1;
            border: 1px solid #e5e5e5;
            padding: 0;
            position: relative;
            & > *{
                border-radius: 50%;
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
            &.today > *{
                background: #e5e5e5;
                font-weight: 500;
                box-shadow: 0 0 0 5px #e5e5e5;
                color: #000;
            }
            &.cal_prev_month, &.cal_next_month{
                color: #999;
                background: #f9f9f9;
            }
            &.cal_has_events{
                &:hover{
                    background: #fafafa;
                }
                a::after{
                    content: "•";
                    position: absolute;
                    margin-left: 50%;
                    left: -3px;
                    font-size: 20px;
                    color: $bg-terciario;
                    margin-top: 9px;
                }
            }
        }
    }
}
