.subsection-imprensa-campanhas-e-produtos-direito-facil{
    #section-leadimage{
        .newsImageContainer {
            @include media-breakpoint-up(md) {
                max-width: 400px;
            }
        }
    }
}

#section-leadimage{
    margin-bottom: 0;
    .newsImageContainer {
        float:right;
        margin: 0 0 6px 6px;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0,0,0,.17);
        overflow: hidden;
        max-width: 150px;
        .figure-img{
            margin-bottom: 0;
        }
        @include media-breakpoint-up(md) {
            max-width: 200px;
        }
    }
}
