body.template-pagina-inicial #portal-breadcrumbs {
    display: none;
}

#portal-breadcrumbs {
    background-color: transparent;
    margin: 15px 0;
    ol {
        margin-bottom: 0;
        padding-left: 0;
        > li + li {
            &::before {
                content: ">";
                color: $bg-quinario;
                padding: 0 3px 0 0;
            }
            &::after {
                display: none;
            }
        }
        li {
            line-height: inherit;
            padding-right: 5px;
            padding-left: 0;
        }
        a {
            color: $bg-quinario;
            font-size: 14px;
            letter-spacing: 0.03em;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
