.fundo-header {
    background-color: $bg-dominante;
    .link-external {
        display: none;
    }
}
#menu-superior {
    @include clearfix;
    @include media-breakpoint-up(md) {
        ul {
            list-style: none;
            margin-bottom: 0;
            padding: 4px 0 5px 0;
            li {
                display: inline-block;
                border-right: 1px solid $bg-secundario;
                padding: 0 30px;
            }
            li:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }
        a {
            color: $bg-secundario;
            font-size: 15px;
            text-decoration: none;
        }
    }
}

#content-header {
    #portal-logo {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#logo-mobile") no-repeat center center;
        background-size: 69px;
        height: 22px;
        margin: 9px 0;
        z-index: 2;
        @include media-breakpoint-up(md) {
            background-image: none;
            height: auto;
            margin: 0;
        }
    }

    #portal-searchbox {
        position: absolute;
        top:5px;
        right: 15px;
        width: 240px;
        z-index: 3;
        @include media-breakpoint-up(md) {
            position: relative;
            top: auto;
            right: auto;
            width: auto;
        }
        .searchSection{
            select{
                appearance: none;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
                background-color: $bg-senario;
                background-position: 104% center;
                background-repeat: no-repeat;
                border: 1px solid #ccc;
                border-right: 0;
                color: #2B2B2B;
                font-size: 14px;
                outline: none;
                padding: 0 0 0 5px;
                height: 30px;
                width: 96px;
            }
        }
        .searchField {
            border: 1px solid #ccc;
            padding: 6px 12px;
            height: 30px;
            width: 100%;
            &:focus{
                border-color: #64bee8;
                outline: 0;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(100,190,232,.6);
            }
        }
        .searchButton{
            background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#lupa-mobile") $bg-secundario no-repeat center center;
            background-size: cover;
            font-size:0;
            border: 1px solid #b2b2b2;
            padding: 7px 14px;
            height: 30px;
            @include media-breakpoint-up(md) {
                background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#lupa") $bg-dominante no-repeat center center; 
                background-size: cover;
            }
        }
    }
    
    .icone-busca {
        display: block;
        position: relative;
        z-index: 4;
        background: url(../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#lupa-mobile) $bg-secundario no-repeat center center;
        background-size: cover;
        height: 30px;
        width: 30px;
        cursor: pointer;
        float: right;
        border: 1px solid #b2b2b2;
        border-radius: 2px;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .icone-selo {
        background: url("../img/selo_2024.png") transparent no-repeat center center;
        background-size: cover;
        margin: 0 auto;
        width: 90px;
        height: 90px;
        @include media-breakpoint-up(xl) {
            width: 110px;
            height: 110px;
        }
    }
    @include media-breakpoint-up(md) {
        background-color: $bg-secundario;

        .logo-tjdft {
            margin: 30px 0;
            span {
                color: $bg-dominante;
                font-size: 15px;
                line-height: 1.2;
            }
        }
    }
}



