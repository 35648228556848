#atendimento-virtual {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    list-style: none !important;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
    .markspeciallinks__icon {
        display: none;
    }
    li {
        width: 200px;
        margin: 15px;
    }
    i {
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        display: block;
        // cor do icone alterada com filtro https://codepen.io/sosuke/pen/Pjoqqp 
        filter: invert(32%) sepia(66%) saturate(1822%) hue-rotate(156deg) brightness(96%) contrast(101%);
        height: 100%;
        margin: 0 auto;
    }
    div {
        height: 100px;
    }
    a, a:visited,  a:link {
        color: $bg-quaternario !important;
        display: block;
        font-size: 18px;
        height: 100%;
        text-align: center;
        text-decoration: none !important;
        &:hover {
            div {
                background-color: $bg-quaternario;
            }
            i {
                filter: invert(100%) sepia(6%) saturate(19%) hue-rotate(171deg) brightness(104%) contrast(100%);
            }
        }
    }
    .chat-pje i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-chat-pje');
    }
    .telefones-whatsapp i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-telefones-whatsapp');
    }
    .balcao i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-balcao');
    }
    .cejusc i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-cejusc');
    }
    .saref i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-saref');
    }
    .agendamento i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-magistrado');
    }
    .alo-tj i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-alo-tj');
    }
    .salas-passivas i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-salas-passivas');
    }
    .servico-digital-assitido i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-servico-digital-assitido');
    }
    .balcao-virtual i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-balcao-virtual');
    }
    .canal-conciliar i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-canal-conciliar');
    }
    .carta-de-servicos i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-carta-de-servicos');
    }
    .central-idoso i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-cji');
    }
    .declinio-competencia i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-declinio-competencia');
    }
    .juizados-especiais i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-juizados-especiais');
    }
    .perguntas-frequentes i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-perguntas-frequentes');
    }
    .violencia-mulher i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-violencia-mulher');
    }
    .atendimento-vitimas i {
        background-image: url('../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#atendimento-vitimas');
    }
    @include media-breakpoint-down(md) {
        justify-content: center;
        li {
            margin:5px;
            width: 135px;
        }

        a, a:visited,  a:link {
            font-size: 16px;
        }

        div {
            height: 80px;
        }
    }
}

.no-externallink .markspeciallinks__icon{
    display: none;
}

.subsection-justica-de-transito #section-byline-document,
.subsection-apresentacao-penal #section-byline-document {
    display: none;
}
