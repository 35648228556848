#portal-column-one .portlet.portletNavigationTree {
    border: 0;
    border-top: 2px solid $bg-quaternario;
    border-radius: 0;
    .markspeciallinks__icon{
        display: none;
    }
    ul {
        background-color: #fafafa;
        z-index: 0;
    }
    .navTreeTopNode {
        //font-size: 18px;
        font-weight: bold;
        text-align: center;
        a:hover, & a.navTreeCurrentItem {
            font-weight: inherit;
            color: $bg-quaternario;
        }
    }
    .navTreeItem{
        border-top: 0;
    }
    a {
        color: $bg-quaternario;
        text-transform: uppercase;
        padding: 5px 0;
        padding-left: 10px; 
        border: 0;
        border-bottom: 1px solid $bg-quaternario;
        svg {
            display: none;
        }
        &.navTreeCurrentItem, &:hover.navTreeCurrentItem, &.navTreeCurrentItem::after  {
            color: $bg-dominante;
            font-weight: 600;
        }
        &:hover, &::after {
            color: black;
            font-weight: normal;
        }
        &:focus {
            text-decoration: none;
        }
        &.folder {
            padding-right: 20px;
            span {
                float: right;
                margin-right: -15px;
            }
        }
    }
    .navTreeItemInPath {
        color: $bg-dominante;
        background-color:  $bg-secundario;
    }
    ul li ul {
        display: none;
    }
    .navTreeCurrentItem + ul, .navTreeItemInPath + ul {
        display: block;
    }
    .navTreeLevel1 {
        a {
            display: list-item;
            border: 0;
            padding-left: 20px;
            color: $bg-dominante;
        }
    }
    .navTreeLevel2 {
        a {
            display: block;
            text-transform: none;
            padding-left: 35px;
            // &:hover, &.navTreeCurrentItem {
            //     color: white;
            // }
        }
    }
}
/*
#portal-column-one .portlet.portletNavigationTree {
    border: 0;
    box-shadow: none;
    border-top: 2px solid $bg-quaternario;
    ul {
        //background-color: @plone-body-bg;
        z-index: 0;
    }
    a {
        color: $bg-quaternario;
        text-transform: uppercase;
        padding: 5px 0;
        padding-left: 10px; 
        border: 0;
        border-bottom: 1px solid $bg-quaternario;
        &::before {
            display: none;
        }
        &.navTreeCurrentItem, &:hover.navTreeCurrentItem, &.navTreeCurrentItem::after  {
            color: $bg-dominante;
            font-weight: 600;
        }
        &:hover, &::after {
            color: black;
            font-weight: normal;
        }
        &:focus {
            text-decoration: none;
        }
        &.folder {
            padding-right: 20px;
            span {
                float: right;
                margin-right: -15px;
            }
        }
    }
    .navTreeItemInPath {
        color: $bg-dominante;
        background-color:  $bg-secundario;
    }
    ul li ul {
        display: none;
    }
    .navTreeCurrentItem + ul, .navTreeItemInPath + ul {
        display: block;
    }
    i.glyphicon {
        display: none;
    }
    .navTreeTopNode {
        a::after {
            content: none;
        }
        a:hover, & a.navTreeCurrentItem {
            font-weight: inherit;
            color: $bg-quaternario;
        }
        size: 18px;
        font-weight: bold;
        text-align: center;
    }
    .navTreeLevel1 {
        a {
            display: list-item;
            border: 0;
            padding-left: 20px;
            color: $bg-dominante;
        }
    }
    .navTreeLevel2 {
        a {
            display: block;
            text-transform: none;
            padding-left: 35px;
            // &:hover, &.navTreeCurrentItem {
            //     color: white;
            // }
            &.folder::after {
                content: none;
            }
        }
    }
}
*/
