#pagina-inicial {
    .markspeciallinks__icon{
        display: none;
    }
    h1 {
        color:#2B2B2B;
        font-size: 27px;
        margin: 20px 0;
        border-bottom: 0;
        padding: 0;
    }
    a, a:hover, a:visited, a:link {
        font-size: 16px;
        color: $bg-dominante;
    }
    .mais {
        text-align: right;
        margin-top: 3px;
        a {
            color: $bg-quaternario;
            font-size: 16px;
            font-weight: 600;
        }
    }
    #ultimas-noticias h1 {
        margin-top: 10px;
    }
    .acessos-rapidos {
        a{
            background-color: $bg-secundario;
            font-weight:600;
            text-align: center;
            text-decoration: none;
            width: 100%;
            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 90%;
            }
        }
        img {
            height: 80px;
            padding: 10px 0;
        }
        span {
            background-color: #fff;
            @include media-breakpoint-up(md) {
                background-color: transparent;
                text-align: left;
                padding-left: 8%;
                .plone-toolbar-left & {
                    padding-left: 4%;
                }
            }
            @include media-breakpoint-up(lg) {
                padding-left: 14%;
            }
        }
    }
}
#principais-acessos {
    padding: 0;
    margin: 0;
    a, a:hover, a:visited, a:link {
        color: $bg-quaternario;
        font-size: 14px;
        font-weight:600;
        text-decoration: none;
        @media (max-width: 450px) {
            font-size: 3.4vw;
        }
        @include media-breakpoint-up(md) {
            font-size: 13px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
     }
    a:hover div {
        background-color: $bg-quaternario;
    }
    li {
        list-style: none;
        text-align: center;
        div {
            $tamanho-circulo: 77%;
            margin: 0 auto 10px auto;
            position: relative;
            width: $tamanho-circulo;
            padding-bottom: $tamanho-circulo;
            background-color: $bg-terciario;
            border-radius: 50%;
        }
        span{
            display:inline-block;
            vertical-align: middle;
            min-height: 60px;
        }
    }
    img {
        position:absolute;
        top:50%; left:50%;
        transform: translate(-50%, -50%);
        margin:0;
        width: 100%;
    }
}
#ultimas-noticias {  
    img {
        height:100%;
        &:hover{
            opacity: 0.9;
        }
    }
    @include media-breakpoint-up(md) {
        .noticias{
            padding-left:0;
            overflow:hidden;
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li {
            display:none;
            border-top:15px solid $bg-terciario;
            background-color:$bg-secundario;
            &:hover {
                background-color: $bg-senario;
            }
            a{
                padding:15px;
                display:block;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
            &.active {
                display:block;
            }
            @include media-breakpoint-up(md) {
                &{
                    display:block;
                    border-top:0;
                    height: 25%;
                    display: table;
                    width: 100%;
                    border-left:15px solid $bg-terciario;
                    a {
                        padding:0 15px;
                        border-top:2px solid #fff;
                        display: table-cell;
                        vertical-align: middle;
                    }
                    &:first-child a{
                        border-top:none;
                    }
                    &.active {
                        display: table;
                        border-left:15px solid $bg-quaternario;
                        a {
                            color: $bg-quaternario;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-up(md) {
            a {
                font-size: 14px;
            }
        }
        @include media-breakpoint-up(lg) {
            a {
                font-size: inherit;
            }
        }
    }

    .carousel-control-next, .carousel-control-prev {
        opacity: 1;
    }
    .carousel-control-prev-icon {
        margin-left:-25px;
    }
    .carousel-control-next-icon {
        margin-right:-25px;
    }

    @include media-breakpoint-up(md) {
        .carousel-controls {
            display:none;
        }
    }
    .mais {
        float:right;
    }
}

#CarouselNoticias .carousel-item, .noticias ul {
    height: auto;
    @include media-breakpoint-up(md) {
        height: 248px;      
    }
    @include media-breakpoint-up(lg) {
        height: 332px;       
    }
    @include media-breakpoint-up(xl) {
        height: 396px;       
    }
}

#divulgacao{
    a{
        display:block;
        margin-bottom: 30px;
        &:hover{
            opacity: 0.9;
        }
    }
    img{
        width: 100%;
    }
}
