.plone-nav {
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    @include clearfix;
  
    li {
      position: relative;
      display: block;
  
      &.has_subtree a {
        padding-right: 2.5em;
      }
  
      label {
        margin: 0;
      }
  
      a {
        position: relative;
        display: block;
        padding: 10px 15px;
        color: white;
        font-size: ceil((14px * 1.15));
        ;
        hyphens: auto;
        word-break: break-word;
  
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: #7099bf;
        }
      }
  
      // Navigation sublevels
      ul {
        padding-left: 0.5em;
        visibility: hidden;
        opacity: 0;
        height: 0;
  
        li {
          a {
            font-size: ceil((14px * 1.1));
            ;
            background-color: #007bb1;
          }
        }
      }
  
      .opener {
        display: none;
  
        &+label:after {
          transform: rotate(0deg);
          transition: ease-in 0.35s;
        }
  
        @include media-breakpoint-up(md) {
          &:checked+label:after {
            transform: rotate(-180deg);
            transition: ease-in 0.35s;
          }
        }
  
        &~ul {
          transition: ease-out 0.35s;
        }
  
        &:checked~ul {
          height: auto;
          transition: ease-in 0.35s;
        }
  
        @include media-breakpoint-up(md) {
          &:checked~ul {
            visibility: visible;
            opacity: 1;
          }
        }
      }
  
      label {
        position: absolute;
        right: 0em;
        top: 0em;
        height: 100%;
        width: 4em;
        text-align: center;
  
        &:after {
          background: darken(#007bb1, 7%);
          border-radius: 4px;
          content: "▼";
          cursor: pointer;
          display: inline-block;
          line-height: 1;
          width: 1.5em;
          height: 1.5em;
          font-size: 1.25em;
          padding: 0.25em;
          right: 0.5em;
          top: 0.45em;
          position: absolute;
        }
      }
    }
}

.plone-navbar {
    background-color: #007bb1;
    color: white;
    position: relative;
    min-height: 50px; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .17);
    @include clearfix;
  }
  
.plone-navbar-header {
    @include clearfix;
  
    @include media-breakpoint-up(md) {
      float: left;
    }
}

.plone-navbar-collapse {
    overflow-x: visible;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    -webkit-overflow-scrolling: touch;
    display: none;
    @include clearfix;

    &.show {
      display: block;
    }
  
    @include media-breakpoint-up(md) {
      width: auto;
      border-top: 0;
      box-shadow: none;
  
      &.plone-collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0; // Override default setting
        overflow: visible !important;
      }
  
      &.show {
        overflow-y: visible;
      }
  
      // Undo the collapse side padding for navbars with containers to ensure
      // alignment of right-aligned contents.
      .navbar-fixed-top &,
      .navbar-static-top &,
      .navbar-fixed-bottom & {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

.plone-navbar-toggle {
  z-index: 1; //just in case
  position: relative;
  float: right;
  margin-top: 8px;
  margin-bottom: 6px;
  padding: 6px 10px;

  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid white;
  border-radius: 4px;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: darken(#7099bf, 15%);
    border-color: darken(#7099bf, 25%);
  }

  // Burgerbar (uncomment for a ≡ menu and comment menu just below)
  /*
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: white;
    margin-top: 2px;
  }
  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
  .icon-bar + .icon-bar + .icon-bar {
    margin-bottom: 3px;
  }
  //end burgerbar icon-bar
  */

  // Menu (That option with a word, witch is language-dependant, is to ensure a higher tap ratio on the menu)
  .icon-bar::after {
    content: "Menu";
    position: relative;
    color: white;
  }

  .icon-bar+.icon-bar::after {
    content: "";
  }

  //end menu icon-bar

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.plone-navbar-nav {
    margin: 0 -15px 0;
  
    >li>a {
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px;
    }
  
    // use selected for the last item in the structure
  
    .current>a,
    .selected>a {
  
      &,
      &:hover,
      &:focus {
        color: white;
        background-color: #7099bf;
      }
    }
  
    // Uncollapse the nav
    @include media-breakpoint-up(md) {
      float: left;
  
      >li {
        float: left;
  
        &.has_subtree a {
          padding-right: 2em;
        }
  
        >a {
          padding-top: 15px;
          padding-bottom: 15px;
        }
  
        label {
          font-size: 1em;
          background: transparent;
  
//          &:after {}
        }
  
        li.has_subtree label:after {
          transform: rotate(-90deg);
  
        }
      }
  
      &.navbar-right:last-child {
        margin-right: -15px;
      }
  
      .has_subtree.inPath>a {
  
        &,
        &:hover,
        &:focus {
          color: white;
          background-color: #7099bf;
        }
      }
  
    }
  }

//customização TJDFT
#mainnavigation-wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    @include media-breakpoint-up(md) {
        position: inherit;
        width: auto;
    }

    button:active,
    button:focus {
        background: 0;
    }

}
.plone-navbar {
    box-shadow: none;
    background-color: transparent;
    margin: 0;
    min-height: auto;
    position: relative;
    z-index:1;
    .container {
        padding: 0;
        @include media-breakpoint-up(md) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .markspeciallinks__icon{
      display: none;
    }
}
.plone-navbar-collapse {
    padding: 0;
    border: 0;
    margin-top: -1px;
}
button.plone-navbar-toggle {
    padding: 10px 30px 7px 15px;
    &:focus, &:active:focus {
        outline: 0;
    }
}
.plone-navbar-toggle {
    float: left;
    margin-top: 0;
    border: none;
    &:hover {
        background-color: transparent;
    }
    .icon-bar {
        border-radius: 1px;
        display: block;
        width: 32px;
        height: 3px;
        background: $bg-secundario;
        transition: all 0.2s;
        & + .icon-bar {
            margin-top: 5px;
        }
        &::after {
            display: none;
        }
    }
    .top-bar {
        transform: rotate(40deg);
        transform-origin: 10% 10%;
    }
    .middle-bar {
        opacity: 0;
    }
    .bottom-bar {
        transform: rotate(-40deg);
        transform-origin: 10% 90%;
    }
    &.collapsed {
        .icon-bar {
            width: 26px;
        }
        .top-bar {
          transform: rotate(0);
        }
        .middle-bar {
          opacity: 1;
        }
        .bottom-bar {
          transform: rotate(0);
        }
    }
}
.plone-nav > {
    li > a:hover, li > a:focus {
        background-color: $bg-dominante;
    }
    @include media-breakpoint-up(md) {
        li > a:hover, li > a:focus {
            background-color: transparent;
        }
    }   
}
.plone-navbar-nav {
    margin: 0;
}
//menu principal

#portal-globalnav, #tjdft-portal-sitemap {
    width: 100%;
    li {
        border-bottom: 1px solid $bg-secundario;
        z-index: 11;
        list-style: none;
        position: unset;
        i.glyphicon {
            display: none;
        }
        &:last-child {
            border-bottom: none;
        }
        .menu-principal.glyphicon {
            top: 0;
            font-family: $font-family-sans-serif;
            &::before {
                content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2VhZWFlYSIgY2xhc3M9ImJpIGJpLWNoZXZyb24tZG93biIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY0NiA0LjY0NmEuNS41IDAgMCAxIC43MDggMEw4IDEwLjI5M2w1LjY0Ni01LjY0N2EuNS41IDAgMCAxIC43MDguNzA4bC02IDZhLjUuNSAwIDAgMS0uNzA4IDBsLTYtNmEuNS41IDAgMCAxIDAtLjcwOCIvPgo8L3N2Zz4K");
                float: right;
            }
        }
        a {
            color: $bg-secundario;
            background-color: $bg-dominante;
            font-weight:600;
            text-transform: uppercase;
            text-decoration: none;
        }
        &.selected {
            a, a:hover, a:focus {
                background-color: $bg-dominante;
            }
            li {
                a, a:hover, a:focus {
                    background-color: transparent;
                }
            }
        }
        //submenu
        ul {
            padding-left: 0;
        }
        .tjdft-navTreeLevel1 {
            display: none;
            padding-top: 15px;
            padding-bottom: 15px;
            background-color:#fafafa;
            height: auto;
            left: 0;
            max-width: none;
            opacity: 1;
            visibility: visible;
            li {
                border: 0;
                a {
                    padding: 0 10px 0 15px;
                    display:block;
                    color: $bg-quaternario;
                    background-color: transparent;
                    line-height: 1.6;
                    margin: 0;
                    &:hover{
                        color: white;
                        background-color:  $bg-quaternario;
                    }
                }
                li {
                    a {
                        padding-left: 30px;
                        text-transform: none;
                        color: $bg-dominante;
                    }
                }
            }
        }
        .tjdft-navTreeLevel2 {
            position: relative;
            opacity: 1;
            visibility: visible;
            height: auto;
            > li > a {
                font-weight: normal;
            }
        }
        .tjdft-navTreeLevel3 {
            display: none;
        }
    }
    // menu principal desktop
    @include media-breakpoint-up(md) {
        position: relative;
        display: table;
        margin: 0;
        > li {
            float: none;
            display: table-cell;
            text-align: center;
            border: 0;
            > a {
                color: $bg-dominante;
                background-color: transparent;
                border-bottom: 2px solid $bg-terciario;
                padding: 10px 0;
                &::after {
                    content: '';
                    width: calc(100% + 30px);
                    height: calc(100% + 30px);
                    position: absolute;
                    top: -30px;
                    left: -30px/2;
                }
                &.vlibras::after {
                    position: relative;
                }
            }
            a:hover, &.selected > a, &.selected > a:hover, &.selected > a:focus {
                background-color: transparent;
                border-color: $bg-quaternario;
                color: $bg-quaternario;
            }
            &.selected > a {
                font-weight: 600;
            }
            &:first-child a {
                margin-left: 0;
            }
            &:last-child a {
                margin-right: 0;
            }
            //submenu
            .tjdft-navTreeLevel1 {
                border-bottom: 2px solid $bg-quaternario;
                position: absolute;
                text-align: left;
                &::after {
                    content: '';
                    width: calc(100% + 100px);
                    height: calc(100% + 30px);
                    position: absolute;
                    top: -10px;
                    left: -50px;
                    z-index: -1;
                }
            }
            .menu-principal.glyphicon::before {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(md) {
        > li > a {
            margin: 0 3px;
            font-size: 12px;
        }
        .tjdft-navTreeLevel1 {
            columns: 3;
            width: 720px;
        }
    }

    @include media-breakpoint-up(lg) {
         > li > a {
            margin: 0 15px;
            font-size: inherit;
        }
        .tjdft-navTreeLevel1 {
            columns: 4;
            width: 960px;
        }
    }

    @include media-breakpoint-up(xl) {
        .tjdft-navTreeLevel1 {
            width: 1140px;
        }
    }

}

