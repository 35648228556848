.skiplinks-wrapper {
    position: absolute;
    top: -100%;
    a.skiplink{
        display: inline-block;
        color: $bg-quaternario;
        text-decoration: none;
    }
    a.skiplink:hover {
        border-color: $bg-quaternario;
        color: $bg-quaternario;
      }
    a.skiplink:focus {
        position: fixed;
        z-index: 9999;
        top: 10px;
        left: 10px;
        padding: 10px;
        border: 2px solid $bg-quaternario;
        background: #fafafa;
        outline: 4px solid $bg-quaternario;
      }
  }
