/*Botões*/
#content #content-core #chat-tjdft a:hover{
    text-decoration: none;
}

#chat-tjdft{
    .markspeciallinks__icon{
        display: none;
    }
    .btn-flutuante-container{
        display: flex;
        flex-direction: column;
        align-items: center; /* Centralizar os botões */
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 2;
    }
    span {
        border-radius: 50px;
        padding: 10px 32px;
        margin: 10px;
        font-size: 18px;
        transition: .3s ease-in-out;
        text-decoration: none;
    }
    .btn-flutuante {
        -webkit-animation: slide 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate-reverse both;
                animation: slide 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate-reverse both;
        z-index: 10;
    }
    .btn-flutuante.um:hover {
        color: #f8f8f8;
        background: #004d4d;
        border-color: #003333;
        box-shadow: 0 0 0 8px #0033335a;
    }
    .btn-flutuante.um {
        color: #f8f8f8;
        background: #006064;
        border: none; /* Remover a borda */
        padding: 8px;
    }
    .btn-flutuante.dois {
        width: 40px;
        padding: 60px 60px;
        margin-bottom: 0px; /* Reduzir o espaço entre os botões */
        background-size: 100px;
        background-image: url(../img/chat-tjdft.png); /* URL da imagem do ícone do Chat do TJDFT criado pela ACS */
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center; 
    }
    .btn-flutuante.dois:hover {
        filter: contrast(85%) hue-rotate(12deg) ; /* Efeito de passar o mouse */
    }
    /*slide*/
    @-webkit-keyframes slide {
      0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
      }
    }
    @keyframes slide {
      0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
      }
    }
    /**#####################**/
    /** Layout para celular **/
    /**#####################**/
    @media(max-width: 787px){
      .btn-flutuante-container{
        display: flex;
        flex-direction: row; /* Alinhar os botões em linha */
        align-items: flex-end; /* Centralizar os botões verticalmente */
        position: fixed;
        bottom: 30px;
        right: 20px;
      }
      span {
        font-size: 15px;
      }
      .btn-flutuante.dois {
        width: 75px;
        background-size:contain;
        padding: 45px 0px 45px 0px;
        margin-right: 0px;
      }
    }
}
