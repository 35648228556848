// icones rede social em notícias e rodapé
.social-share {
    margin-top: 2rem;
    .markspeciallinks__icon {
        display: none;
    }
    a {
        display: inline-block;
        text-indent: 100%;
        overflow: hidden;
        width: 30px;
        height: 30px;
        margin-left: 10px;
    }
    .instagram {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#instagram-colorido")  no-repeat center;
        background-size: cover;
    }
    .facebook {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#facebook-colorido") no-repeat center;
        background-size: cover;
    }
    .youtube {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#youtube-colorido")  no-repeat center;
        background-size: cover;
        height: 21px;
        margin-bottom: 4px;
    }
    .twitter {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#twitter-colorido")no-repeat center;
        background-size: cover;
    }
    .flickr {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#flickr-colorido")  no-repeat center;
        background-size: cover;
        height: 14px;
        margin-bottom: 8px;
    }
    .linkedin {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#linkedin-colorido")  no-repeat center;
        background-size: cover;
    }
    .spotify {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#spotify-colorido")  no-repeat center;
        background-size: cover;
    }
    .whatsapp {
        background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#whatsapp-colorido")  no-repeat center;
        background-size: cover;
    }
    .compartilhe{
        @include media-breakpoint-up(md) {
            padding-right: 0;
            .borda{
                display: block;
            }
        }
    }
    .siga{
        @include media-breakpoint-up(md) {
            padding-left: 0;
        }
    }
    p {
        margin: 3px 0;
        font-size: 14px;
        color: $bg-quinario;
    }
    .borda {
        display: inline-block;
        border-top: 1px solid $bg-quinario;
        margin-top: 10px;
    }
    .first{
        margin-left: 0;
    }
}
.youtube {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#youtube")  no-repeat center;
}   
.flickr {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#flickr")  no-repeat center;
} 
.podcast {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#spotify")  no-repeat center;
}
.twitter {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#twitter")  no-repeat center;
} 
.facebook {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#facebook")  no-repeat center;
} 
.instagram {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#instagram")  no-repeat center;
}
.linkedin {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#linkedin")  no-repeat center;
}
.whatsapp {
    background: url("../img/tjdft-icons.svg?hash=dc5418d392eb28693935a3763d46e384fcbecabd90262048e10e45627de45cf4#whatsapp") #25D366 no-repeat center;
}
.icone-social {
    background-size: cover;
}
