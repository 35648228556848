// conteúdo
body{
  background-color: #fafafa;
  color:#2B2B2B;
}
main{
  margin-bottom: 0;
}
a {
  text-decoration: none;
  &.state-private{
    color: #c4183c !important;;
  }
  &.state-pending{
    color:#a5a912 !important;
  }
}
p {
  margin-bottom: 10px;
  &.recuado{
    padding-left: 54%;
  }
}

h1 {
  font-weight:600;
  font-size: 30px;
  line-height: 1.1;
  color:#2B2B2B;
}
h2 {
  font-size: 24px;
  line-height: 1.1;
  margin: 1.25em 0 0.5em;
  font-weight: bold;
  color: #5B9B9E;
}
h3 {
  font-size: 20px;
  line-height: 1.1;
  margin: 0.75em 0 0.25em;
  color: $bg-quinario;
  font-weight: 600;
}
ul li{
  &::marker{
    color:$bg-terciario
  }
}
img {
  &.image-right{
    float:right;
    margin-left: .5rem;
  }
  &.image-left{
    float:left;
    margin-right: .5rem;
  }
}
/*video, iframe {
  .img-responsive();
}*/
strong, b {
  font-weight: 600;
}
#content, .mce-content-body {
  font-size: 16px;
  margin-bottom: 15px;
  header {
    margin-bottom: 0;
    .lead{
      display: none; //sera exibido somente na visao das pastas, colocado display block ao final.
      font-size: 18px;
      font-weight: 400;
      color: #696969;
      margin-bottom: 10px;
    }
  }
  h1 {
      font-size: 30px;
      color: $bg-quaternario;
      padding-bottom: 9px;
      margin: 0 0 10px;
      border-bottom: 1px solid #ccc;
  }
  #section-byline-document,
  #section-byline-newsitem{
    color: #696969;
    font-weight: 400;
    margin-bottom: 10px;
  }
  #content-core {
    margin-bottom: 0;
    a{
      border: 0;
      &:hover{
        text-decoration: underline;
      }
      &:visited{
        color: #7E18D8;
      }
    }
  }
  // prioridade de exibição do formulário de busca
  input.searchPage {
    z-index: 0;
  }
}

//exibe descrição apenas na visão da pasta
.portaltype-folder {
  #content header .lead {
    display: block;
  }
}

// remoção da opção de visualizar o código fonte exceto para managers
#form-widgets-IRichText-text_text_format,
#form-widgets-IRichTextBehavior-text_text_format {
    display: none;
}
.userrole-manager {
  #form-widgets-IRichText-text_text_format,
  #form-widgets-IRichTextBehavior-text_text_format {
    display: block;
  }
}

#section-related {
  margin-top: 2rem;
  .section-heading {
    font-weight: 600;
  }
  .media-body a {
    color: $link-color;
  }
  .media {
    margin-bottom: 0;
  }
}
